import React, { createContext, useState } from "react";
import requestModule from "../helperFunctions/REQ";
import GLOBAL from "../containers/GLOBAL/GLOBAL";


export const AdvertisersContext = createContext();

function AdvertisersContextProvider(props) {

    const [advertisers, setAdvertisers] = useState([]);
    const [singleAdvertiser, setSingleAdvertiser] = useState({});
    const [loading, setLoading] = useState(true);


    const fetchAdvertisers = async () => {

        try {
            setLoading(true);

            const advertisers = await requestModule('GET', `${GLOBAL.domainClickGet}/api/advertisers`, null, true)

            setAdvertisers(advertisers.data)
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            return false;

        }
    }
    const generateInvoice = async (revenueInDateRange, company) => {

        try {
            // setLoading(true);

            const { data } = await requestModule('POST', `${GLOBAL.domainClickGet}/api/generate-advertiser-invoice/${company._id}`, {
                fromdate: revenueInDateRange.fromdate,
                todate: revenueInDateRange.todate,
            }, true)
            console.log(data, 42)
            let el = document.createElement("a")

            el.setAttribute("href", `${GLOBAL.domainClickGet}/api/download-advertiser-invoice/${data.filename}`)

            document.querySelector("body").appendChild(el);

            el.click();
            document.querySelector("body").removeChild(el);

            setLoading(false)


            return true;
        } catch (error) {
            console.log(error);

            setLoading(false);
            alert("An error occured in the server")

            return false;

        }
    }
    const fetchAdvertiserGrandDetails = async (id, fromdate, todate) => {

        try {
            setLoading(true);

            const singleAdvertiser = await requestModule('GET', `${GLOBAL.domainClickGet}/api/advertisers/${id}?fromdate=${fromdate}&todate=${todate}`, null, true)

            setSingleAdvertiser(singleAdvertiser.data)
            console.log(singleAdvertiser)

            return true;
        } catch (error) {
            setLoading(false);

            return false;

        } finally {
            setLoading(false);

        }
    }

    return (
        <AdvertisersContext.Provider
            value={{
                advertisers,
                fetchAdvertisers,
                loading,
                singleAdvertiser,
                fetchAdvertiserGrandDetails,
                generateInvoice
            }}
        >
            {props.children}
        </AdvertisersContext.Provider>
    );
}

export default AdvertisersContextProvider;
