import React from "react"
import { LinearProgress, withStyles, lighten } from '@material-ui/core';
import classes from "./LoaderComponent.module.css"
import Logo from "../../images/advancerlogo.png"
const LoaderComponent = () => {
    const BorderLinearProgress = withStyles({
        root: {
            height: 40,
            // width: 600,
            borderRadius: 20,
            backgroundColor: lighten('#eee', 0.5),
            // boxShadow: "1px 0px 10px #eee"
        },
        bar: {
            height: 7,
            borderRadius: 20,
            backgroundColor: '#111',


        },
    })(LinearProgress);
    return <div className={classes.LoadScreen} >

        <div className={classes["lds-ellipsis"]}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <img src={Logo} height={50} alt="DESTA LOGO" />


    </div >
}

export default LoaderComponent