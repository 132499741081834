import React, { createContext, useState } from "react";
import { useAlert } from 'react-alert'


// import axios from "axios";
import RequestModule from "../helperFunctions/REQ"
import GLOBAL from "../containers/GLOBAL/GLOBAL";
export const CreateSubIDContext = createContext();

function CreateSubIDContextProvider(props) {
    const [trafficList, setTrafficList] = useState([]);
    const [dataOwnerList, setDataOwnerList] = useState([]);
    const [subs, setsubs] = useState([]);

    const myAlert = useAlert()


    const fetchSubIDs = async () => {
        //`${Global.domainClickGet}/api/getallsubs-23`

        try {
            const subs = await RequestModule('GET', `${GLOBAL.domainClickGet}/api/getallsubs-23`)
            console.log(subs, "subs")
            setsubs(subs.data.reverse())
            // myAlert.show('Sub ids available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch sub ids', {
                type: 'error'
            });
        }
    }

    const createSubID = async (subid, dataowner, traffic) => {
        try {
            const response = await RequestModule('POST', `${GLOBAL.domainClickGet}/api/subid/create`, {
                subid, dataowner, traffic
            }, true)
            console.log(response);
            myAlert.show('Successfully Created Sub ID', {
                type: 'success'
            });
            return true;
        } catch (error) {
            console.log(error)
            const msg = error?.response?.data?.message;
            myAlert.show(msg || 'Could not fetch traffic', {
                type: 'error'
            });

            return false;
        }
    }

    const fetchTraffic = async () => {
        try {
            const trafficList = await RequestModule('GET', `${GLOBAL.domainNameCheap}/api/gettraffic`)
            // console.log(trafficList, 24)
            setTrafficList(trafficList.data)
            // myAlert.show('Traffic available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch traffic', {
                type: 'error'
            });
        }
    }
    const fetchDataowner = async () => {
        try {
            const dataownerList = await RequestModule('GET', `${GLOBAL.domainDataowner}/api/get-profit-sharers`, null, true)
            // console.log(dataownerList, 24)
            setDataOwnerList(dataownerList.data)
            // myAlert.show('Data owners available', {
            //     type: 'success'
            // });

        } catch (error) {
            console.log(error)
            myAlert.show('Could not fetch dataowner', {
                type: 'error'
            });
        }
    }




    //    SET ERROR TO NULL

    return (
        <CreateSubIDContext.Provider
            value={{
                trafficList,
                fetchTraffic,
                fetchSubIDs,
                subs,
                dataOwnerList,
                fetchDataowner,
                createSubID,


            }}
        >
            {props.children}
        </CreateSubIDContext.Provider>
    );
}

export default CreateSubIDContextProvider;
