//@ts-nocheck
import React, { Component } from "react";

class GLOBAL extends Component {
  static domain =
    process.env.NODE_ENV === "production"
      ? "https://destagroupllc.tech"
      : "http://localhost:7000";
  static domaincampaign =
    process.env.NODE_ENV === "production"
      ? "https://campaigns.destagroupllc.tech"
      : "http://localhost:20000";
  static domainDOCampaigns =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:2999"
      : "http://localhost:2999";
  static domainHomeSim =
    process.env.NODE_ENV === "production"
      ? "https://homesim.destagroupllc.tech"
      : "http://localhost:11112";
  static domainHomeSimB =
    process.env.NODE_ENV === "production"
      ? "https://homesimb.destagroupllc.tech"
      : "http://localhost:11115";
  static domainMinis =
    process.env.NODE_ENV === "production"
      ? "https://mini.destagroupllc.tech"
      : "http://localhost:11115";
  static domainRedisGet =
    process.env.NODE_ENV === "production"
      ? "https://redisget.destagroupllc.tech"
      : "http://localhost:8817";
  static domainOfferGroup =
    process.env.NODE_ENV === "production"
      ? "https://offergroup.destagroupllc.tech"
      : "http://localhost:2989";
  static domainUserScrub =
    process.env.NODE_ENV === "production"
      ? "https://scrubfilegroup.destagroupllc.tech"
      : "http://localhost:11983";
  static domainbesttext =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9103"
      : "http://localhost:9103";
  static domain2way =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:2003"
      : "http://localhost:2003";
  static domainXeeb =
    process.env.NODE_ENV === "production"
      ? "https://smpp.destagroupllc.tech"
      : "http://localhost:9977";
  static domainNameCheapUrlSave =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:2483"
      : "http://localhost:2483";
  static domainConversation =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:2389"
      : "http://localhost:2389";
  static domainDataowner =
    process.env.NODE_ENV === "production"
      ? "https://dataowner.destagroupllc.tech"
      : "http://localhost:2379";
  static messageFormatter =
    process.env.NODE_ENV === "production"
      ? "https://upload.destagroupllc.tech"
      : "http://localhost:4321";
  static paypal =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9174"
      : "http://localhost:9174";
  static domainClickGet =
    process.env.NODE_ENV === "production"
      ? "https://clickget.destagroupllc.tech"
      : "http://localhost:2222";
  static domainConvHome =
    process.env.NODE_ENV === "production"
      ? "https://conversion.destagroupllc.tech"
      : "http://localhost:2402";
  static domainredisset =
    process.env.NODE_ENV === "production"
      ? "https://redisset.destagroupllc.tech"
      : "http://localhost:8818";
  static domainGorilla =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7113"
      : "http://localhost:7113";
  static domainClickSocket =
    process.env.NODE_ENV === "production"
      ? "https://clicksocket.destagroupllc.tech"
      : "http://localhost:3333";
  static domainTwilio =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:5000"
      : "http://localhost:5000";
  static domainTwilioLV =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9111"
      : "http://localhost:9111";
  static domainTwilioTest =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:5000"
      : "http://localhost:5000";
  static domainTwilioRetries =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9219"
      : "http://localhost:9219";
  static domainGV =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7112"
      : "http://localhost:7112";
  static domainSinch =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7121"
      : "http://localhost:7121";
  static domainMobiniti =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9000"
      : "http://localhost:9000";
  static domainpine =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9090"
      : "http://localhost:9090";
  static domainss7 =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:8199"
      : "http://localhost:8199";
  static domainNameCheap =
    process.env.NODE_ENV === "production"
      ? "https://namecheap.destagroupllc.tech"
      : "http://localhost:8023";
  static blacklist =
    process.env.NODE_ENV === "production"
      ? "https://blacklist.main.destagroupllc.tech"
      : "http://localhost:8024";
  static blue =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7191"
      : "http://localhost:7191";
  static blacklistUpload =
    process.env.NODE_ENV === "production"
      ? "https://blacklistupload.destagroupllc.tech"
      : "http://localhost:7192";
  static domainpineaccountone =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7800"
      : "http://localhost:7800";
  static domainpineaccountthree =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7802"
      : "http://localhost:7802";
  static domainpineaccount15 =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:7803"
      : "http://localhost:7803";
  static domainrejects =
    process.env.NODE_ENV === "production"
      ? "http://159.89.55.0:9029"
      : "http://localhost:9029";
  render() {
    return;
  }
}

export default GLOBAL;
